
import axios from 'axios';
export default {
    async createIncomes(params)  {
        return await axios.post(`incomes/create` , params)
    },
    async createIncomesList(params)  {
        return await axios.post(`incomes/create/list` , params)
    },
    async updateIncomesColumn(column , value , data)  {
        return await axios.put(`incomes/update_list?${column}=${value}` , data)
    },
    async deleteIncomesList(list)  {
        return await axios.delete(`incomes/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportIncomes(columns)  {
        return await axios.get(`incomes/report?${columns}`)
    },
    async getAllIncomes()  {
        return await axios.get(`incomes/all`)
    },
    async getOneIncomes(income_id)  {
        return await axios.get(`incomes/all/${income_id}`)
    },
    async getIncomesByColumn(column , value)  {
        return await axios.get(`incomes/filter?column=${column}&value=${value}`)
    },
    async deleteIncomes(income_id)  {
        return await axios.delete(`incomes/delete/${income_id}`)
    },
    async updateIncomes(income_id , params)  {
        return await axios.put(`incomes/update/${income_id}` , params)
    },
}
        