
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap px-4 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.incomes.edit_path">
                        <template v-slot:item="{item}">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                {{ item.title }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
            <form @submit.prevent="updateIncomes" autocomplete="off">
                <v-layout row wrap>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="incomes.income_date" type="date" :label="$store.getters.language.data.incomes.income_date" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="accounts" v-model="incomes.account_id" dense  filled outlined item-text="account_name"
                                item-value="account_id" :return-object="false" :label="$store.getters.language.data.incomes.account_name">
                        </v-select>
                    </v-flex>
                    
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="incomes.income_amount" type="text" :label="$store.getters.language.data.incomes.income_amount" dense
                            class="mx-1" filled outlined required :prefix="$store.getters.store.store_payment == 'usd' ? '$' : ''">
                        </v-text-field>
                    </v-flex>
                    
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="money_move_types" v-model="incomes.money_move_type_id" dense  filled outlined item-text="money_move_type_name"
                                item-value="money_move_type_id" :return-object="false" :label="$store.getters.language.data.incomes.money_move_type_name">
                        </v-select>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="users" v-model="incomes.user_id" dense  filled outlined item-text="user_name"
                            item-value="user_id" :return-object="false" :label="$store.getters.language.data.incomes.user_name" :disabled="$store.getters.user.user_role != 'admin'">
                        </v-select>
                    </v-flex>
                    
                     <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-textarea rows="1" auto-grow v-model="incomes.income_note" type="text" :label="$store.getters.language.data.incomes.income_note" dense
                            class="mx-1" filled outlined>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="success" type="submit" :disabled="buttonDisable">{{$store.getters.language.data.incomes.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/incomes.request.js'
    export default {
        data() {
            return {
                incomes: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            accounts(){
                return this.$store.getters.accounts_list
            },
            money_move_types(){
                return this.$store.getters.money_move_types_list
            },
            users(){
                return this.$store.getters.users_list
            },
            buttonDisable() {
                return !this.incomes.income_date ||
                    !this.incomes.account_id ||
                    !this.incomes.income_amount ||
                    !this.incomes.money_move_type_id ||
                    !this.incomes.user_id
            }
        },
        mounted(){
            this.id = this.$route.params.id
            if(this.$store.state.store_initialized) {
                this.getOneIncomes()
            }
        },
        methods: {
            getOneIncomes() {
                this.loading = true
                requests.getOneIncomes(this.id).then(r =>{
                    if (r.status == 200) {
                        this.incomes = r.data.row
                        this.incomes.income_date = new Date(r.data.row.income_date).toISOString().split('T')[0];
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateIncomes() {
                this.loading = true
                requests.updateIncomes(this.id, {
                    income_id: this.incomes.income_id,
                    account_id: this.incomes.account_id,
                    user_id: this.incomes.user_id,
                    income_amount: this.incomes.income_amount,
                    income_date: this.incomes.income_date,
                    money_move_type_id: this.incomes.money_move_type_id,
                    income_note: this.incomes.income_note
                }).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.update_success,
                            color: 'success'
                        }
                        this.loading = false
                        this.$router.push('/incomes-list');
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    